import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

function VideoPage() {
  const { id } = useParams();
  const [transcription, setTranscription] = useState('');

  useEffect(() => {
    const fetchTranscription = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/videos/${id}`);
        setTranscription(response.data.transcription);
      } catch (error) {
        console.error("Error fetching transcription:", error);
      }
    };

    fetchTranscription();
  }, [id]);

  return (
    <div>
      <h1>Video Transcription</h1>
      <p>{transcription}</p>
    </div>
  );
}

export default VideoPage;