import axios from 'axios';

const API = axios.create({
  baseURL: 'http://51.83.185.24/api',
});

export const createTranscription = async (link) => {
  try {
    const response = await API.post('/videos', { link });
    return response.data;
  } catch (error) {
    console.error("Error creating transcription:", error);
    throw error;
  }
};