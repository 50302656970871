import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createTranscription } from '../services/api';

function Index() {
  const [link, setLink] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      const data = await createTranscription(link);
      navigate(`/video/${data.id}`);
    } catch (error) {
      console.error("Failed to generate transcription:", error);
    }
  };

  return (
    <div>
      <h1>Insert YouTube Link</h1>
      <input
        type="text"
        placeholder="Paste YouTube link here"
        value={link}
        onChange={(e) => setLink(e.target.value)}
      />
      <button onClick={handleSubmit}>Generate Transcription</button>
    </div>
  );
}

export default Index;